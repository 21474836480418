<template>
  <div
    class="rounded-full p-[4px] mx-auto z-10 relative bg-activesection-base text-center"
    :class="[
      {
        'text-white': !props.inverted,
        'text-activesection-base': props.inverted,
      },
      isSmall ? 'w-[78px] h-[78px]' : 'w-[108px] h-[108px]',
    ]"
    :style="wrapperStyles"
  >
    <div
      v-if="props.icon"
      class="flex justify-center border-4 border-white rounded-full place-items-center"
      :class="[
        {
          'bg-white': props.inverted,
        },
        isSmall ? 'w-[70px] h-[70px]' : 'w-[100px] h-[100px]',
      ]"
      :style="iconStyles"
    >
      <FaIcon
        :key="key"
        :icon="props.icon"
        :classes="isSmall ? 'text-2xl' : 'text-4xl'"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { IconData } from '~~/src/server/transformers/iconTransformer';
import FaIcon from '@/components/fa-icon.vue';

const props = defineProps({
  icon: {
    type: Object as PropType<IconData>,
    required: false,
    default: () => null as IconData,
  },
  image: {
    type: Object as PropType<any>,
    required: false,
    default: () => null as any,
  },
  small: {
    type: Boolean,
    default: false,
  },
  iconColor: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: String,
    default: '',
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});

const isSmall = ref(false);
const wrapperStyles = ref('');
const iconStyles = ref([]);
const key = ref(0);

onMounted(() => {
  isSmall.value = props.small;
  wrapperStyles.value = 'background-color: ' + props.backgroundColor;
  iconStyles.value = [
    'background-color: ' + props.backgroundColor,
    'color: ' + props.iconColor,
    'border-color: ' + props.iconColor,
  ];
});

watch(
  () => props.small,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      key.value = key.value + 1;
      isSmall.value = newVal;
    }
  },
);

/**
 * IMAGE HTML :)
    <!--<div
      v-if="tile.image"
      class="rounded-full w-[100px] h-[100px] border-4 border-white place-items-center flex overflow-hidden"
    >
      <img :src="tile.image" class="min-w-full min-h-full" />
    </div>-->
 */
</script>
<style lang="postcss">
.text-activesection-base {
  color: var(--activesection-base);
}
</style>
